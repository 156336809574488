import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const Contact = () => {
  return (
    <Container maxWidth="sm" id="contact" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom align="center">
        Contact Me
      </Typography>
      <Typography variant="body1" paragraph align="center">
        I’m always open to discussing new projects or opportunities.
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <Button href="mailto:ben.ludwin@gmail.com" variant="contained" color="primary" sx={{ m: 1 }}>
          Email Me
        </Button>
        <Button href="https://github.com/benludwin" target="_blank" rel="noopener" variant="contained" sx={{ m: 1 }}>
          GitHub
        </Button>
        <Button href="https://www.linkedin.com/in/albert-ludwin" target="_blank" rel="noopener" variant="contained" sx={{ m: 1 }}>
          LinkedIn
        </Button>
      </Box>
    </Container>
  );
};

export default Contact;

import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';

const projectData = [
  {
    title: 'Portfolio Website',
    description: 'Developed a portfolio website using React and AWS services to showcase projects and experience.',
    technologies: 'AWS, React, Python, Django, MongoDB',
    github: 'https://github.com/benludwin/portfolio',
    private: false
  },
  {
    title: 'Freelance Serverless App',
    description: 'Architected a serverless application for a client using AWS Lambda, API Gateway, and DynamoDB.',
    technologies: 'AWS Lambda, API Gateway, DynamoDB',
    github: 'https://github.com/benludwin/freelance-serverless-app',
    private: true
  }
];

const Projects = () => {
  return (
    <Container maxWidth="lg" id="projects" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom align="center">Projects</Typography>
      <Grid container spacing={3}>
        {projectData.map((project, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>{project.title}</Typography>
                <Typography variant="body2" color="textSecondary">{project.technologies}</Typography>
                <Typography variant="body1" paragraph>{project.description}</Typography>
                {project.private && (
                  <Typography variant="body2" color="error">This repository is private.</Typography>
                )}
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" href={project.github} target="_blank" rel="noopener">
                  View on GitHub
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Projects;

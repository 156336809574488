import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

const skills = [
  'Python', 'JavaScript', 'C++', 'React', 'AWS Lambda', 'API Gateway', 'DynamoDB'
];

const Skills = () => {
  return (
    <Container maxWidth="md" id="skills" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom align="center">
        Technical Skills
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
        {skills.map((skill, index) => (
          <Chip key={index} label={skill} color="primary" />
        ))}
      </Box>
    </Container>
  );
};

export default Skills;

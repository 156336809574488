import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Summary = () => {
  return (
    <Container maxWidth="md" id="summary" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom>
        About Me
      </Typography>
      <Typography variant="body1" paragraph>
        I’m Albert Benjamin Ludwin, a passionate Software Engineer with expertise in building scalable, cloud-native solutions using AWS. My background spans front-end and serverless back-end development, specializing in AWS Lambda, API Gateway, DynamoDB, and React.js.
      </Typography>
      <Typography variant="body1" paragraph>
        My key strengths include architecting serverless applications, developing web apps with CI/CD pipelines, and implementing solutions that reduce costs while improving system performance.
      </Typography>
    </Container>
  );
};

export default Summary;

import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const experienceData = [
  {
    title: 'Freelance Front-end and Serverless Back-end Developer',
    date: 'May 2023 - Present',
    details: [
      'Architected scalable serverless applications using AWS Lambda, API Gateway, and DynamoDB.',
      'Deployed web apps using AWS Amplify and S3, with CI/CD pipelines for seamless updates.',
      'Integrated AWS Cognito for secure user authentication.',
      'Automated cloud infrastructure using CloudFormation.'
    ],
  },
  {
    title: 'MEDsmart Medical Technology',
    date: 'Jan 2023 - May 2023',
    details: [
      'Developed web apps using React.js, AppSync, Lambda, and DynamoDB, boosting scalability by 50%.',
      'Integrated IoT solutions using AWS IoT Core, Python, and MQTT for real-time data transmission.'
    ],
  },
  {
    title: 'CliniComp',
    date: 'Sep 2019 - Oct 2022',
    details: [
      'Designed interactive flow sheets for medical personnel using Ext JS, Webix, C, and SQL.',
      'Developed a Patient Registration app in React.js and C++/SQL, improving user management.',
      'Led a Scrum team, resulting in a 20% increase in on-time delivery.'
    ],
  }
];

const Experience = () => {
  return (
    <Container maxWidth="lg" id="experience" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom align="center">
        Work Experience
      </Typography>
      <Grid container spacing={3}>
        {experienceData.map((job, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {job.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                  {job.date}
                </Typography>
                <ul>
                  {job.details.map((detail, idx) => (
                    <li key={idx}>
                      <Typography variant="body2">{detail}</Typography>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Experience;
